<template>
  <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com --> 
  <footer class="bg-neutral-200 text-center dark:bg-neutral-700 lg:text-left w-full h-10 flex flex-col justify-center"> <!-- 调整宽度和高度，并使用 flex 布局使文本内容垂直居中 -->
    <div class="p-4 text-center text-neutral-700 dark:text-neutral-200 text-sm">
      © 2024 Copyright:
      <a class="text-neutral-800 dark:text-neutral-400" href="https://tw-elements.com/">活水工作室 · 活水金句</a>
    </div>
    <div class="h-8 dark:bg-neutral-700"></div>
  </footer>
</template>







<script>
export default {
  name: "AppFooter"
};
</script>
