

// import NavBar from './components/NavBar.vue';
import HomePage from './components/HomePage.vue';
import SearchContent from './components/SearchContent.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // 路由对应的组件，参考App.vue的<router-view>
  {
    path: '/',
    component: HomePage , // 你需要替换成实际的组件名称
  },
  {
    path: '/search',
    component: SearchContent  , // 你需要替换成实际的组件名称
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
